.addUser {
}

.footerButton {
  margin: 0 5px;
}

.form-container {
  border: 1px solid #e8e8e8;
  padding: 20px;
  border-radius: 5px;
}
