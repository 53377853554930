@tailwind base;
@tailwind components;
@tailwind utilities;
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* body {
  margin: 0;
  font-family: 'Popinees', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* index.css */
body {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.roundprogress {
  height: 50px;
  width: 50px;
}

/* App.css */
.app-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.ant-progress-text {
  color: black !important;
}

/* ======= */
/* .notifications-dropdown {
  max-width: 300px;
  max-height: 400px;
  overflow-y: auto;
}

.notifications-dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.notifications-dropdown li {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0;
}

.notifications-dropdown li.read {
  background-color: #f9f9f9;
}

.notifications-dropdown li.unread {
  background-color: #7aa9d4;
}

.notification-icon .ant-badge {
  position: relative;
  display: inline-block;
}

.notification-icon .ant-popover-inner {
  width: 350px;
  padding: 0;
} */

.notifications-dropdown {
  max-width: 270px;
  max-height: 300px;
  overflow-y: auto;
}

.notifications-dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.notifications-dropdown li {
  /* padding: 10px; */
  cursor: pointer;
  /* border-bottom: 1px solid #fcf9f9; */
}

.notifications-dropdown li.read {
  background-color: white;
  /* background-color:#001529; */
  /* border-radius: 10px; */
  /* color: white; */
  /* margin-bottom: 10px;
  margin-left: 10px;
  margin-right:10px ; */
  /* box-shadow: 0 2px 4px 4px rgba(8, 8, 8, 0.18); */
}

.notifications-dropdown li.unread {
  background-color:#fffaef;
  /* background-color: #e2e8f0; */
  /* border-radius: 10px;
  margin-bottom: 10px; */
  /* box-shadow: 0 2px 4px 4px rgba(0, 0, 0, 0.20); */
  /* color: white; */
  /* margin-left: 10px; */
  /* margin-right:10px ; */
  
}


.notification-icon .ant-badge {
  position: relative;
  display: inline-block;
}

.notification-icon .ant-popover-inner {
  width: 350px;
  padding: 0;
}

/* button {
  margin-left: 10px;
} */

.notifications-dropdown {
  max-width: 280px;
  max-height: 400px;
  overflow-y: scroll;
}

.notifications-dropdown::-webkit-scrollbar {
  width: 0px;
}

.notifications-dropdown::-webkit-scrollbar-thumb {
  background: transparent; 
}

.notifications-dropdown::-webkit-scrollbar-track {
  background: transparent; 
}

@keyframes vibrate {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-2px);
  }
  50% {
    transform: translateX(2px);
  }
  75% {
    transform: translateX(-2px);
  }
  100% {
    transform: translateX(2px);
  }
}
:where(.css-dev-only-do-not-override-d2lrxs).ant-popover .ant-popover-inner{
  /* background-color:#e2e8f0; */
  background-color:white;
  text-align: center;
  /* padding-left:1px ;
  padding-right: 1px; */
  padding: 0 0 3px 0; 
}
:where(.css-dev-only-do-not-override-d2lrxs).ant-popover .ant-popover-title {
  text-decoration: underline;
  
}